import { send } from "../index";

export function getfinanceDayRevenueStatementList(data, opts = {}) {
  // 营收报表
  return send({
    url: "/admin/financeDayRevenueStatement/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaItemList(data, opts = {}) {
	// 影票座位报表
  return send({
    url: "/admin/orderBase/cinemaItemList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaOrderDetail(data, opts = {}) {
  // 影票座位报表详情
  return send({
    url: "/admin/orderBase/cinemaOrderDetail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function cinemaOrderAfterSale(data, opts = {}) {
  // 影票退款申请
  return send({
    url: "/admin/orderAfterSale/cinemaOrderAfterSale.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function ticketAgree(data, opts = {}) {
  // 影票同意退款
  return send({
    url: "/admin/orderAfterSale/confirmAudit.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function ticketReject(data, opts = {}) {
  // 影票拒绝退款
  return send({
    url: "/admin/orderAfterSale/rejectAudit.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaOrderList(data, opts = {}) {
  // 影票订单报表
  return send({
    url: "/admin/orderBase/cinemaOrderList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getOrderBaseSnackList(data, opts = {}) {
  // 卖品订单报表
  return send({
    url: "/admin/orderBase/snackList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaRefundList(data, opts = {}) {
  // 退票报表
  return send({
    url: "/admin/orderAfterSale/cinemaRefundList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaChangeList(data, opts = {}) {
  // 改签报表
  return send({
    url: "/admin/orderAfterSale/cinemaChangeList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getFilmInfoList(data, opts = {}) {
  // 电影信息表
  return send({
    url: "/admin/filmReportForm/filmInfo/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getFilmResourceList(data, opts = {}) {
  // 电影资料表
  return send({
    url: "/admin/filmReportForm/filmResource/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getFilmActorList(data, opts = {}) {
  // 演职人员表
  return send({
    url: "/admin/filmReportForm/filmActor/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUserUnionCardList(data, opts = {}) {
  // 一卡通绑定信息
  return send({
    url: "/admin/userUnionCard/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateIsDisabledUserUnionCard(data, opts = {}) {
  // 用户一卡通启用/禁用
  return send({
    url: "/admin/userUnionCard/updateIsDisabled.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function chargeUserUnionCard(data, opts = {}) {
  // 用户一卡通充值
  return send({
    url: "/admin/userUnionCard/charge.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUnionCardOrderList(data, opts = {}) {
  // 一卡通购买报表
  return send({
    url: "/admin/unionCardOrder/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUserUnionCardChargeOrderList(data, opts = {}) {
  // 一卡通充值报表
  return send({
    url: "/admin/userUnionCardChargeOrder/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCouponPacketOrderList(data, opts = {}) {
  // 礼包购买列表
  return send({
    url: "/admin/couponPacketOrder/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUnionCardChargeOrderById(data, opts = {}) {
  // 获取一卡通充值记录
  return send({
    url: "/admin/userUnionCardChargeOrder/cardList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUnionCardFlow(data, opts = {}) {
  // 一卡通消费列表
  return send({
    url: "/admin/userUnionCardFlow/outcomeList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUserSpecialMemberRecord(data, opts = {}) {
  // 特色会员开通报表
  return send({
    url: "/admin/userSpecialMemberRecord/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getOrderInvoiceList(data, opts = {}) {
  // 发票申请列表
  return send({
    url: "/admin/orderInvoice/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function invoiceProcess(data, opts = {}) {
  // 发票审核
  return send({
    url: "/admin/orderInvoice/process.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function invoiceBatchProcess(data, opts = {}) {
  // 发票审核
  return send({
    url: "/admin/orderInvoice/processBatch.do",
    method: "POST",
    data,
    ...opts,
  });
}